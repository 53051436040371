<template>
  <div>
    <headers />
    <div class="content">
      <div class="page page-one">
        <div class="page-content">
          <img
            :src="require('@/assets/image/h5/customer/content1.png')"
            alt=""
            class="page-img"
          />
          <div class="page-main">
            <div class="introduce-title">淄博博山连顺汽车附件有限公司</div>
            <div class="introduce-con">
              <p class="introduce">
                博山某汽车附件有限公司是导向臂衬套系列、吊杆、稳定杆衬套系列、
                矿山设备、重卡开口套系列、钢板弹簧衬套系列等产品专业生产加工的公司。
              </p>
              <p class="introduce">
                数壤实施团队于2022年6月，在公司进行了企业基本信息调研、现场用
                电勘察、设计实施方案、现场实施及培训答疑，两天半的时间实现了从
                企业调研到公共服务平台上线实施试运行。之后也建立有效的沟通工作组群，在群内与企业进行有效及时的互动，积极为企业解答平台运行过程中遇到的操作及多种功能实现的问题。
              </p>
              <p class="introduce">
                经两个月的平台运行，切实为企业在生产效率提高和能源合理利用及节省方面带来有效改善。此外，企业在基础能源模块上线后，充分了解了当前用电峰平谷的规则，根据不同时段的电价差异合理的调整自有生产计划及特殊工序工作时段，使用能成本得到了有效的改善节省。
              </p>
            </div>
            <div class="customer_per">
              <div class="customer_per_title">平台实施效果</div>
              <div class="customer_per_range">
                <div class="one">
                  <div class="up-name">人员效率</div>
                  <div class="up-line">
                    <div class="progress" style="width: 10%">
                      <div class="progress-bg"></div>
                    </div>
                  </div>
                  <div class="up-num">
                    <i class="el-icon-top add"></i>
                    <CountTo :startVal="0" :endVal="10" :duration="4000" />%
                  </div>
                </div>
                <div class="one">
                  <div class="up-name">设备利用效率</div>
                  <div class="up-line">
                    <div class="progress" style="width: 10%">
                      <div class="progress-bg"></div>
                    </div>
                  </div>
                  <div class="up-num">
                    <i class="el-icon-top add"></i>
                    <CountTo :startVal="0" :endVal="10" :duration="4000" />%
                  </div>
                </div>
                <div class="one">
                  <div class="up-name">用能效率</div>
                  <div class="up-line">
                    <div class="progress" style="width: 25%">
                      <div class="progress-bg"></div>
                    </div>
                  </div>
                  <div class="up-num">
                    <i class="el-icon-top add"></i>
                    <CountTo :startVal="0" :endVal="25" :duration="4000" />%
                  </div>
                </div>
                <div class="one">
                  <div class="up-name">生产综合效率</div>
                  <div class="up-line">
                    <div class="progress" style="width: 15%">
                      <div class="progress-bg"></div>
                    </div>
                  </div>
                  <div class="up-num">
                    <i class="el-icon-top add"></i>
                    <CountTo :startVal="0" :endVal="15" :duration="4000" />%
                  </div>
                </div>
                <div class="one">
                  <div class="up-name">产品一次通过率</div>
                  <div class="up-line">
                    <div class="progress" style="width: 5%">
                      <div class="progress-bg"></div>
                    </div>
                  </div>
                  <div class="up-num">
                    <i class="el-icon-top add"></i>
                    <CountTo :startVal="0" :endVal="5" :duration="4000" />%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page">
        <div class="page-content">
          <img
            :src="require('@/assets/image/h5/customer/content2.png')"
            alt=""
            class="page-img"
          />
          <div class="page-main">
            <div class="introduce-title">淄博纳仕福机械制造有限公司</div>
            <div class="introduce-con">
              <p class="introduce">
                数壤实施团队于2022年6月在公司现场充分了解企业组织
                架构及应用平台人员信息、勘察配电柜走线排布，提前做好公共服务平台的系统用户预配置。并于6.23早上工厂正式开工上班前到现场完成了能源智能采集终端的并线安装，现场完成硬件的配置调试。同时，组织企业生产相关管理层及班组长集中培训、认识服务平台制造模块的功能及特点优势。实施制造管理模块，教授生产工单实际录入，以实例引导操作员工参与培训，进行工艺SOP、工艺流程模板的创建，试行报工操作，熟悉整个工单流程。了解企业人才需求信息，在服务平台人才中心模块协助企业提交所需人才的具体需求。利用上午半天的时间，基本实现了BOSS中心、人才中心、制造管理和能源管理的上线实施运行。
              </p>
              <p class="introduce">
                至今运行两月多的时间，企业反馈运行良好。企业负责人反应公共服务平台解决了之前生产工单过程不透明、工单迟交的诸多问题，在平台制造管理和能源管理模块的支持下，不仅实现了生产效率25%左右的提升，也达到了用电节省近30%的效果。
              </p>
            </div>
            <div class="customer_per">
              <div class="customer_per_title">平台实施效果</div>
              <div class="customer_per_range">
                <div class="one">
                  <div class="up-name">人员效率</div>
                  <div class="up-line">
                    <div class="progress" style="width: 8%">
                      <div class="progress-bg"></div>
                    </div>
                  </div>
                  <div class="up-num">
                    <i class="el-icon-top add"></i>
                    <CountTo :startVal="0" :endVal="8" :duration="4000" />%
                  </div>
                </div>
                <div class="one">
                  <div class="up-name">设备利用效率</div>
                  <div class="up-line">
                    <div class="progress" style="width: 12%">
                      <div class="progress-bg"></div>
                    </div>
                  </div>
                  <div class="up-num">
                    <i class="el-icon-top add"></i>
                    <CountTo :startVal="0" :endVal="12" :duration="4000" />%
                  </div>
                </div>
                <div class="one">
                  <div class="up-name">用能效率</div>
                  <div class="up-line">
                    <div class="progress" style="width: 30%">
                      <div class="progress-bg"></div>
                    </div>
                  </div>
                  <div class="up-num">
                    <i class="el-icon-top add"></i>
                    <CountTo :startVal="0" :endVal="30" :duration="4000" />%
                  </div>
                </div>
                <div class="one">
                  <div class="up-name">生产综合效率</div>
                  <div class="up-line">
                    <div class="progress" style="width: 17%">
                      <div class="progress-bg"></div>
                    </div>
                  </div>
                  <div class="up-num">
                    <i class="el-icon-top add"></i>
                    <CountTo :startVal="0" :endVal="17" :duration="4000" />%
                  </div>
                </div>
                <div class="one">
                  <div class="up-name">产品一次通过率</div>
                  <div class="up-line">
                    <div class="progress" style="width: 6%">
                      <div class="progress-bg"></div>
                    </div>
                  </div>
                  <div class="up-num">
                    <i class="el-icon-top add"></i>
                    <CountTo :startVal="0" :endVal="6" :duration="4000" />%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page onex">
        <div class="page-content">
          <img
            :src="require('@/assets/image/h5/customer/content3.png')"
            alt=""
            class="page-img"
          />
          <div class="page-main">
            <div class="introduce-title">淄博鲁明泵业</div>
            <div class="introduce-con">
              <p class="introduce">
                淄博鲁明泵业有限公司位于山东—博山，企业是集研发、生产、销售于一体的泵类产品制造类企业。以屏蔽泵、磁力泵、氟塑料泵等为主要产品。该公司是典型的泵类企业，该类企业具有产品种类众多，零部件变化频繁，工艺差别巨大，产品设计不断更新的特点。
              </p>
              <p class="introduce">
                数壤平台以轻量化契合泵类企业的生产特点，在工艺变化的灵活、工序变化的多样、工艺修改的操作便捷等等方面与泵类企业完美契合，做到为泵类企业减负、降本、增效，同时开启数壤商城的优品售卖，通过专业的工业品电商帮助拓宽渠道。
              </p>
            </div>
            <div class="customer_per">
              <div class="customer_per_title">平台实施效果</div>
              <div class="customer_per_range">
                <div class="one">
                  <div class="up-name">人员效率</div>
                  <div class="up-line">
                    <div class="progress" style="width: 10%">
                      <div class="progress-bg"></div>
                    </div>
                  </div>
                  <div class="up-num">
                    <i class="el-icon-top add"></i>
                    <CountTo :startVal="0" :endVal="10" :duration="4000" />%
                  </div>
                </div>
                <div class="one">
                  <div class="up-name">设备利用效率</div>
                  <div class="up-line">
                    <div class="progress" style="width: 10%">
                      <div class="progress-bg"></div>
                    </div>
                  </div>
                  <div class="up-num">
                    <i class="el-icon-top add"></i>
                    <CountTo :startVal="0" :endVal="10" :duration="4000" />%
                  </div>
                </div>
                <div class="one">
                  <div class="up-name">用能效率</div>
                  <div class="up-line">
                    <div class="progress" style="width: 15%">
                      <div class="progress-bg"></div>
                    </div>
                  </div>
                  <div class="up-num">
                    <i class="el-icon-top add"></i>
                    <CountTo :startVal="0" :endVal="15" :duration="4000" />%
                  </div>
                </div>
                <div class="one">
                  <div class="up-name">生产综合效率</div>
                  <div class="up-line">
                    <div class="progress" style="width: 15%">
                      <div class="progress-bg"></div>
                    </div>
                  </div>
                  <div class="up-num">
                    <i class="el-icon-top add"></i>
                    <CountTo :startVal="0" :endVal="15" :duration="4000" />%
                  </div>
                </div>
                <div class="one">
                  <div class="up-name">产品销售量</div>
                  <div class="up-line">
                    <div class="progress" style="width: 15%">
                      <div class="progress-bg"></div>
                    </div>
                  </div>
                  <div class="up-num">
                    <i class="el-icon-top add"></i>
                    <CountTo :startVal="0" :endVal="15" :duration="4000" />%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page onex">
        <div class="page-content">
          <img
            :src="require('@/assets/image/h5/customer/content4.png')"
            alt=""
            class="page-img"
          />
          <div class="page-main">
            <div class="introduce-title">潍坊胜利石化机械有限公司</div>
            <div class="introduce-con">
              <p class="introduce">
                公司主要生产销售石油机械、化工机械，承揽一、二类压力容器等机械设备配件的设计、测绘及加工制
                造任务。主要产品有3ZB、5ZB高压柱塞注水泵系列；3ZB/JN节能型高压柱塞注水泵；3ZCB160、5ZCB127供水
                掺水泵；3ZY液力平衡增压泵、ZSDB新型往复泵、增压泵系列；3ZJB注聚合物泵系列；FQNB型撬装泥浆泵系
                列等，形成高压泵产品系列，广泛应用于石油、化工、水力、电力等工业领域。
              </p>
              <p class="introduce">
                数壤通过上线平台、软硬件设备实施，快速实现工业研发、生产制造、能源和设备管理等全链条数字化升级，降本增效。
              </p>
            </div>
            <div class="customer_per">
              <div class="customer_per_title">平台实施效果</div>
              <div class="customer_per_range">
                <div class="one">
                  <div class="up-name">人员效率</div>
                  <div class="up-line">
                    <div class="progress" style="width: 9%">
                      <div class="progress-bg"></div>
                    </div>
                  </div>
                  <div class="up-num">
                    <i class="el-icon-top add"></i>
                    <CountTo :startVal="0" :endVal="9" :duration="4000" />%
                  </div>
                </div>
                <div class="one">
                  <div class="up-name">设备利用效率</div>
                  <div class="up-line">
                    <div class="progress" style="width: 15%">
                      <div class="progress-bg"></div>
                    </div>
                  </div>
                  <div class="up-num">
                    <i class="el-icon-top add"></i>
                    <CountTo :startVal="0" :endVal="15" :duration="4000" />%
                  </div>
                </div>
                <div class="one">
                  <div class="up-name">用能效率</div>
                  <div class="up-line">
                    <div class="progress" style="width: 20%">
                      <div class="progress-bg"></div>
                    </div>
                  </div>
                  <div class="up-num">
                    <i class="el-icon-top add"></i>
                    <CountTo :startVal="0" :endVal="20" :duration="4000" />%
                  </div>
                </div>
                <div class="one">
                  <div class="up-name">生产综合效率</div>
                  <div class="up-line">
                    <div class="progress" style="width: 16%">
                      <div class="progress-bg"></div>
                    </div>
                  </div>
                  <div class="up-num">
                    <i class="el-icon-top add"></i>
                    <CountTo :startVal="0" :endVal="16" :duration="4000" />%
                  </div>
                </div>
                <div class="one">
                  <div class="up-name">产品一次通过率</div>
                  <div class="up-line">
                    <div class="progress" style="width: 6%">
                      <div class="progress-bg"></div>
                    </div>
                  </div>
                  <div class="up-num">
                    <i class="el-icon-top add"></i>
                    <CountTo :startVal="0" :endVal="6" :duration="4000" />%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page">
        <div class="page-content">
          <img
            :src="require('@/assets/image/h5/customer/content5.png')"
            alt=""
            class="page-img"
          />
          <div class="page-main">
            <div class="introduce-title">青岛青铸装备有限公司</div>
            <div class="introduce-con">
              <p class="introduce">
                公司成立于2014年，主营铸造机械设备的研发、设计、生产，可提供覆盖铸造工厂全部工部的整体解决方案，拥有潮模砂、自硬砂铸造以及抛喷丸清理、除尘装备的自有知识产权和先进技术，可以提供覆盖铸造工厂全部工部的整体解决方案。
              </p>
              <p class="introduce">
                2021年底，数壤团队与青铸集团及装备公司研发、生产、财务等部门多次交流，分别从研发管理PLM以及生产制造管理模块进行深入探讨合作，协助应用研发图文档管理、项目管理、依图纸自动生成BOM、BOM一键转工单、在产工单及报工统计、外协件管理等多项迫切需求，理顺公司研发与生产部门独立业务管理以及联动功能，极大提高公司协作运营效率。同时，数壤与青铸在铸机设备后市场服务领域有较深的合作意向，双方协商开发专属铸机设备领域的产业大脑系统，用于公司销售到市场面上的设备远程管理及维护，同时提升其自身设备在市场的竞争力。
              </p>
            </div>
            <div class="customer_per">
              <div class="customer_per_title">平台实施效果</div>
              <div class="customer_per_range">
                <div class="one">
                  <div class="up-name">人员效率</div>
                  <div class="up-line">
                    <div class="progress" style="width: 15%">
                      <div class="progress-bg"></div>
                    </div>
                  </div>
                  <div class="up-num">
                    <i class="el-icon-top add"></i>
                    <CountTo :startVal="0" :endVal="15" :duration="4000" />%
                  </div>
                </div>
                <div class="one">
                  <div class="up-name">设备利用效率</div>
                  <div class="up-line">
                    <div class="progress" style="width: 12%">
                      <div class="progress-bg"></div>
                    </div>
                  </div>
                  <div class="up-num">
                    <i class="el-icon-top add"></i>
                    <CountTo :startVal="0" :endVal="12" :duration="4000" />%
                  </div>
                </div>

                <div class="one">
                  <div class="up-name">生产综合效率</div>
                  <div class="up-line">
                    <div class="progress" style="width: 15%">
                      <div class="progress-bg"></div>
                    </div>
                  </div>
                  <div class="up-num">
                    <i class="el-icon-top add"></i>
                    <CountTo :startVal="0" :endVal="15" :duration="4000" />%
                  </div>
                </div>
                <div class="one">
                  <div class="up-name">产品销售量</div>
                  <div class="up-line">
                    <div class="progress" style="width: 15%">
                      <div class="progress-bg"></div>
                    </div>
                  </div>
                  <div class="up-num">
                    <i class="el-icon-top add"></i>
                    <CountTo :startVal="0" :endVal="15" :duration="4000" />%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page">
        <div class="page-content">
          <img
            :src="require('@/assets/image/h5/customer/content6.png')"
            alt=""
            class="page-img"
          />
          <div class="page-main">
            <div class="introduce-title">博众精工-苏州科技大学-数壤互联网工业平台</div>
            <div class="introduce-con platform">
              <p class="introduce">
                数壤团队几年来一直服务于博众精工的研发管理体系，迄今为止已经帮助博众精工完成了产品生命周期系统（PLM)的三期的研发和实施，实现了从图文档到BOM，编码管理等内容。数壤长期来和苏科大电子工程学院合作，在产学研和产教融合方面，联合企业，学校在平台上共同实施课程开发和人才定向培养。在数壤互联网工业平台上，基于数字金属服务博众精工的内容，数字金属和苏科大共同研发了一门工业互联网系统的课程，并联合博众精工，将学生的创新，实习，和企业定向培训，工业互联网平台实训等内容结合，完成了基于平台和实训，教学的校企合作项目。并通过平台，将相关课程和合作模式推广到全国高校及企业。
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="page">
        <div class="page-content">
          <div class="intro-header">博山·科技金融双碳生态平台</div>
          <div class="intro">
            <p>全国首个融合型数字化科技金融双碳产业生态公共服务体系;</p>
            <p>目前已上线企业53家,预计到2023年5月前完成服务淄博博山区1300家企业。</p>
          </div>
          <div class="cont_box">
            <div class="cont_1">
              <span>定位 : </span>政府服务体系的延伸,强力拉动产业升级发展的“缆绳”
            </div>
            <div class="cont_1">
              <span>目标 : </span>促进产业自身演进,推动产业进化成为“新实业”
            </div>
            <div class="cont_1">
              <span>功效 : </span>服务产业“本体”,提升企业整体运营效率
            </div>
            <div class="cont_1">
              <span>愿景 : </span
              >协同政府培育产业发展沃壤,强化产业细胞,将服务势能转化为效率功能
            </div>
          </div>
          <div class="cont_2">
            “政府做好指挥员、服务员、安全员、平台做好裁判员、教导员、辅导员”
          </div>
          <div class="cont_3">
            <div>
              <img
                class="cont_img"
                :src="require('@/assets/image/h5/customer/content7.png')"
                alt=""
              />
              <div class="cont_name">企业全局规划</div>
            </div>
            <div>
              <img
                class="cont_img"
                :src="require('@/assets/image/h5/customer/content8.png')"
                alt=""
              />
              <div class="cont_name">企业服务</div>
            </div>
            <div>
              <img
                class="cont_img"
                :src="require('@/assets/image/h5/customer/content9.png')"
                alt=""
              />
              <div class="cont_name">产业科技赋能</div>
            </div>
          </div>
        </div>
      </div>

      <div class="page_sh">
        <div class="page-content">
          <div class="introsh-title">上海天地软件园智慧零碳园区平台</div>
          <div style="text-align:center">
            <img
              class="img_sh"
              :src="require('@/assets/image/h5/customer/content12.png')"
              alt=""
            />
          </div>
        </div>
      </div>

      <div class="page_four">
        <div class="page-content">
          <div class="intro-title">四大特性: 碳家底、碳监管、碳目标、碳成效</div>
          <div class="intro_cardList">
            <div class="intro_card">
              <div class="intro_card_div">
                <img
                  :src="require('@/assets/image/customer/icon1.png')"
                  alt=""
                  class="card_img1"
                />
              </div>
              <div class="intro_card_title">摸清碳家底</div>
              <div class="intro_card_content">
                实现双碳与两高数据汇集,摸清碳家底,帮助政府全面掌握城市和行业碳排放情况,辅助政府开展决策分析与产业指导
              </div>
            </div>

            <div class="intro_card">
              <div class="intro_card_div">
                <img
                  :src="require('@/assets/image/customer/icon2.png')"
                  alt=""
                  class="card_img2"
                />
              </div>
              <div class="intro_card_title">推动碳监管</div>
              <div class="intro_card_content">
                实现对区域、重点排放单位及各领域的监管,支撑政府出台相关碳管控指标和奖惩措施,引导促进城市和行业有序实现碳中和
              </div>
            </div>

            <div class="intro_card">
              <div class="intro_card_div">
                <img
                  :src="require('@/assets/image/customer/icon3.png')"
                  alt=""
                  class="card_img3"
                />
              </div>
              <div class="intro_card_title">落实碳目标</div>
              <div class="intro_card_content cont3">
                构建双控目标预警机制,辅助政府落实完成能源+双碳双控目标,推动企业整体转型升级,助力企业迎接绿色经济
              </div>
            </div>

            <div class="intro_card">
              <div class="intro_card_div">
                <img
                  :src="require('@/assets/image/customer/icon4.png')"
                  alt=""
                  class="card_img4"
                />
              </div>
              <div class="intro_card_title">展示碳成效</div>
              <div class="intro_card_content cont4">
                展示政府在双碳政策下的治理成就,展示开展的各项示范项目及推广成效,明确绿色发展中探索出的亮点与特色
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page_last">
        <div class="page-content">
          <div class="page_tDiv">
            <img
              class="page_tImg"
              :src="require('@/assets/image/h5/customer/content10.png')"
              alt=""
            />
          </div>
          <div class="page-main">
            <div class="intro-title">马鞍山博望区刀模具工业大脑</div>

            <div class="introduce-con">
              <p class="introduce">
                作为本行业产业链中重要的配套服务分支,各类工具、机具的技术水平一定程度上决定了行业生产工艺的进步与生产效率的提升.博望区位于马鞍山市最东端,地处长三角经济圈和南京一小时都市圈内,与南京江宁、溧水、高淳三区接壤,素有中国“刃模具之乡”的美誉,机床及刃模具产业历来是当地的传统特色产业和主导产业。
              </p>
              <p class="introduce">
                近年来,博望区机床产业逐渐壮大,接连获得中国国家火炬计划博望折机床及刃模具特色产业基地、全国剪折机床产业和知名品牌展示范区等称号。
              </p>
              <p class="introduce">
                数壤通过服务了博望区刃模具行业的二十家企业的数字化转型,帮助他们建立起从研发到制造的全过程管控,从而把规模化的数据再次聚合赋能,通过地区和行业两个数据维度,建立了马鞍山博望区的刀模具工业大脑。
              </p>
              <p class="introduce">
                通过对地区行业企业全生命周期的数字化转型服务,综合采集企业的研发、订单、产能、供应链、质量、库存、等数据.根据行业和地域特性,全面展示地区产业发展现状,并提供针对性的预测和统计,帮助政府对产业进行引导,对企业进行有针对性的扶助。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footers />
  </div>
</template>
<script>
import headers from "@/components/H5/hend";
import footers from "@/components/H5/foot";
import CountTo from "vue-count-to";
export default {
  components: {
    headers,
    footers,
    CountTo,
  },
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
a {
  color: white;
}

.content {
  min-width: 320px;
  max-width: 100vw;
  margin: 0 auto;
  overflow-x: hidden;
  font-family: "Helvetica Neue", Helvetica, STHeiTi, sans-serif;
  font-size: 14px;
  line-height: 1.6;
  .page {
    margin: 0 15px 30px;
    background: #ffffff;

    .page-content {
      width: 100%;

      .intro-header {
        position: relative;
        margin-bottom: 20px;
        padding-left: 10px;
        height: 23px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        line-height: 23px;
        color: rgba(0, 0, 0, 0.85);
        &::before {
          position: absolute;
          top: 2px;
          left: 0;
          content: "";
          width: 3px;
          height: 23px;
          background: #308971;
          border-radius: 2px;
        }
      }

      .intro {
        height: 45px;
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 15px;
      }
      .cont_box {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        .cont_1 {
          width: 100%;
          //   height: 27px;
          border-radius: 2px;
          background: rgba(48, 137, 113, 0.1);
          color: #308971;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-size: 10px;
          line-height: 14px;
          margin-bottom: 10px;
          padding: 7px 0 7px 5px;
          span {
            font-weight: 600;
          }
        }
      }

      .cont_2 {
        margin-top: 1px;
        margin-bottom: 20px;
        // height: 15px;
        font-size: 11px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        line-height: 15px;
      }
      .cont_3 {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        .cont_img {
          width: 117px;
          height: 71px;
        }
        .cont_name {
          margin-top: 8px;
          height: 14px;
          font-size: 10px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          line-height: 14px;
        }
      }
      .page-img {
        width: 345px;
        width: 100%;
        height: 160px;
      }
      .page-main {
        border-left: 1px solid #e9e9e9;
        border-right: 1px solid #e9e9e9;
        border-bottom: 1px solid #e9e9e9;
        border-radius: 0px 0 10px 10px;

        .introduce-title {
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 17px;
          margin: 0 0 10px 15px;
          padding-top: 20px;
        }
        .introduce-con {
          // width: 315px;
          font-size: 10px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          line-height: 21px;
          margin: 0 15px;
        }
        .platform {
          padding-bottom: 26px;
        }
      }
      .customer_per {
        margin-top: 20px;
        .customer_per_title {
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          // height: 33px;
          background: #fafafa;
          border-radius: 2px 2px 0px 0px;
          border-top: 1px solid #e9e9e9;
          border-bottom: 1px solid #e9e9e9;
          padding: 8px 0 8px 15px;
        }
        .customer_per_range {
          display: flex;
          flex-wrap: wrap;
          padding: 22px 15px 20px 15px;
          justify-content: space-between;
          .one {
            height: 15px;
            line-height: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 400;
            font-size: 11px;
            font-family: PingFangSC-Regular, PingFang SC;
            margin-bottom: 20px;
            color: rgba(0, 0, 0, 0.65);
            &:last-child {
              margin: 0;
            }
            .up-name {
              margin-right: 5px;
              height: 15px;
              line-height: 15px;
              font-size: 11px;
              text-align: right;
            }
            .up-line {
              width: 50px;
              height: 5px;
              background: #f5f5f5;
              border-radius: 3px;
              margin-right: 5px;
              .progress-bg {
                border-radius: 3px 0px 0px 3px;
                background: #f5222d;
                height: 5px;
                animation: animationLine 4s forwards;
              }
              @keyframes animationLine {
                0% {
                  width: 0;
                }

                100% {
                  width: 100%;
                }
              }
            }
            .up-num {
              display: flex;
              align-items: center;
              height: 11px;
              font-family: HelveticaNeue;
              color: rgba(0, 0, 0, 0.65);
              line-height: 11px;
              .add {
                color: #f5222d;
              }
            }
          }
        }
      }
    }
  }
  .page-one {
    margin-top: 30px;
  }
  .onex {
    margin-bottom: 31px;
  }
  .page_sh {
    margin: 0 15px;
    padding-top: 20px;
    .page-content {
      width: 100%;
      .introsh-title {
        position: relative;
        margin-bottom: 14px;
        padding-left: 10px;
        height: 23px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        line-height: 23px;
        color: rgba(0, 0, 0, 0.85);
        &::before {
          position: absolute;
          top: 2px;
          left: 0;
          content: "";
          width: 3px;
          height: 23px;
          background: #308971;
          border-radius: 2px;
        }
      }
      .img_sh {
        width: 355px;
        height: 170px;
      }
    }
  }
  .page_four {
    margin: 0 15px;
    padding-top: 15px;
    .page-content {
      .intro-title {
        height: 15px;
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 15px;
      }

      .intro_cardList {
        margin-top: 8px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        .intro_card {
          width: 167px;
          //   height: 142px;
          padding-bottom: 10px;
          background: #fff;
          box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.05);
          border-radius: 5px;
          // margin-right: 12px;
          &:hover {
            box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
            transform: translate3d(0, -2px, 0);
          }
          &:nth-child(3) {
            margin-top: 10px;
          }
          &:nth-child(4) {
            margin-top: 10px;
          }
          &:last-child {
            margin-right: 0;
          }
          .intro_card_div {
            width: 40px;
            height: 40px;
            margin: 10px auto 8px;
            background: rgba(48, 137, 113, 0.1);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            .card_img1 {
              width: 18px;
              height: 18px;
              margin: 1px;
            }
            .card_img2 {
              width: 19px;
              height: 19px;
              margin: 3px;
            }
            .card_img3 {
              width: 18px;
              height: 18px;
            }
            .card_img4 {
              width: 17px;
              height: 15px;
              margin: 3px 2px;
            }
          }
          .intro_card_title {
            height: 15px;
            font-size: 11px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            line-height: 15px;
            text-align: center;
            margin-bottom: 3px;
          }
          .intro_card_content {
            width: 143px;
            font-size: 10px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);
            line-height: 14px;
            margin: 0 auto;
            text-align: center;
          }
          .cont3 {
            width: 149px;
          }
          .cont4 {
            width: 153px;
          }
        }
      }
    }
  }
  .page_last {
    margin: 25px 15px 30px;
    .page-content {
      .page_tDiv {
        width: 100%;
        .page_tImg {
          //   width: 355px;
          width: 100%;
          height: 85px;
        }
      }
      .page-main {
        border-left: 1px solid #e9e9e9;
        border-right: 1px solid #e9e9e9;
        border-bottom: 1px solid #e9e9e9;
        border-radius: 0px 0 10px 10px;
        margin: 0 5px;
        .intro-title {
          height: 17px;
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 17px;
          padding: 20px 0 10px 15px;
        }
        .introduce-con {
          font-size: 10px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          line-height: 21px;
          padding:0 15px 20px;
        }
      }
    }
  }
}
</style>
